@font-face {
  font-family: mainFont;
  src: url(./assets/misc/MS-Sans-Serif-8pt.ttf);
  font-display: block;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/misc/OutlineFont.woff2) format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/misc/IconFont.woff2) format('woff2');
  font-display: block;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

* {
  font-family: mainFont;
  color:white;
}

.active {
  color: #cf8f11 !IMPORTANT;
}

body,
html,#root {
  height:100%;
  padding: 0 0;
  margin: 0 0;
  background-color: black;
}

.body {
  background-color: black;
}

div, .profile-pic {
  border-radius: 4px;
}

#header-section {
  grid-column: 1/-1;
  grid-row: 1;
  height: 80px;
  background-color: #292929;
}

#icon, #contact-icon{
  width: 100%;
  height: 100%;
}

#dna-icon{
  position: absolute; 
  right: 0; 
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit:fill;
  overflow: hidden;
}

#icon-section {
  touch-action: none;
  position: relative;
  background-color: transparent;
  grid-column: 1;
  grid-row: 2;
  height: 50vh;
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: none;
  resize: both;
  min-width: 200px;
  min-height: 200px;
  max-width: 40vw;
  max-height: 40vh;
}

#icon-sparkle{
  opacity:0;
  position: absolute;
  height:30%;
  animation: fadeinout 1s linear infinite;
  -webkit-animation: fadeinout 1s linear infinite;
  -moz-animation: fadeinout 1s linear infinite;
  -o-animation: fadeinout 1s linear infinite;
}

.default-loader{
  position:absolute;
  width: 25%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  animation: load 1s linear infinite;
  -webkit-animation: load 1s linear infinite;
  -moz-animation: load 1s linear infinite;
  -o-animation: load 1s linear infinite;
}

#left-body {
  background-color: #292929;
  box-shadow: 0 0 10px #0a0a0a;
  height: calc(100% - 100px);
  overflow-y: auto;
  padding: 5px 5px;
  margin-bottom: 30px;
}

#left-section {
  grid-column: 1;
  grid-row: 3;
  min-height: 350px;
  background-color: #292929;
  padding: 10px;
}

#main-body {
  background-color: black;;
  box-shadow: 0 0 10px #C0C0C0;
  height: calc(100% - 30px);
  overflow-y: auto;
  text-align: center;
  margin: 10px 10px;
}

.bgVideo {
  position: absolute; 
    right: 0; 
    bottom: 0;
    width: 100%; 
    height: 100%; 
    z-index: 0;
    object-fit:fill;
    overflow: hidden;
}

#main-container {
  display: grid;
  grid-template-columns: min-content 1fr 10vw;
  grid-template-rows: auto 0fr 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  height: calc(100% - 20px);
  background-size: cover;
  background-repeat: no-repeat;
}

#main-container>div {
  box-shadow: -1px -1px #ff4848, 1px 1px 1px #8b0000;

}

#main-section {
  grid-column: 2;
  grid-row: 2/4;
  box-shadow: -1px -1px #ff4848, 1px 1px 1px #8b0000  !IMPORTANT;
  margin-left: 10px;
  background-color: #292929;
}

.post {
  display: flex;
  background-color: #292929;
  box-shadow: 2px 1px #8b0000, -2px -1px #ff4848;
  margin-bottom: 5px;
  padding: 2px 2px;
  overflow:auto;
  align-items: center;
  word-break: break-all;
}

.post-details{
  text-align: left;
}

.postText{
  margin-top:0px;
}

.username, #contact-id-title, #gender-title{
  margin:5px 0 5px 0;
  font-size: .7rem;
  color:rgb(204, 204, 204);
}

#comment-section{
  display:flex;
  width:100%;
}

#comment-txt{
  flex:1;
  color: black;
  font-weight: bold;
  width: 0;
}

#comment-btn{
  background-color: #292929;
  margin-left: 10px;
}

.profile-pic{
  max-height: 40px;
  margin-right:10px;
  padding:2px;
  border:1px solid #8b0000;
}

.tab {
  font-weight: bold;
  word-wrap: break-word;
 overflow-wrap: break-word;
 -webkit-hyphens: auto;
 -moz-hyphens: auto;
 -ms-hyphens: auto;
 hyphens: auto;
  box-shadow: 0 -1px #ff4848, 1px 1px 1px #8b0000;
  background-color: #292929;
  transition: height 0.5s ease, background-color 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tab-container {
  grid-column: 3;
  grid-row: 2/4;
  box-shadow: none !IMPORTANT;
}

.tab-content {
  display: none;
  width:100%;
  height:100%;
}

.tab-content#Contact-content{
  position: relative;
}

.tab:hover {
  color: #ffca3a;
  cursor: pointer;
}

#file-outer-container{
  position: relative;
  min-height:100%;
}

#file-inner-container{
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  width:100%;
}

.file{
  display: inline-flex;
  margin: 20px;
  max-width:120px;
  cursor: pointer;
}

#video-icon-holder{
    position: relative;
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    left:50%;
    transform: translate(-50%,0%);
    -webkit-transform: translate(-50%,0%);
}

.video-logo, .video-logo-flash{
  position: absolute;
  top:0;
  left:0;
  width: 48px;
    height: 48px;
}

.video-logo-flash{
  animation: flash 1s linear infinite;
  -webkit-animation: flash 1s linear infinite;
  -moz-animation: flash 1s linear infinite;
  -o-animation: flash 1s linear infinite;
}

.photo-logo{
  max-width:80px;
  max-height:80px;
  box-shadow: 0px 0px 10px #8b0000;
  animation: flicker 1s linear infinite;
  -webkit-animation: flicker 1s linear infinite;
  -moz-animation: flicker 1s linear infinite;
  -o-animation: flicker 1s linear infinite;
}

#video-container{
  touch-action: none;
  width:fit-content;
  max-width:60%;
  min-width:60%;
  height:fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding:5px 20px 20px 20px;
  background-color: #292929;
  box-shadow:  -1px -1px #ff4848, 1px 1px #8b0000;
  overflow:auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 10;
}


#video-controls{
  display:flex;
  justify-content: center;
  align-items: center;
}

input[type='range'] {
  height:1px;
  width: 15%;
  -webkit-appearance: none;
  background-color: black;
  box-shadow: 0px 0px 10px 1px rgb(255, 255, 255);
}

input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 25px;
  background: #C0C0C0;
  box-shadow: inset 1px 1px white, 1px 1px 1px black;
}

input[type="range"]::-moz-range-progress {
background-color: black; 
}
input[type="range"]::-moz-range-track {  
background-color: black;
}

input[type="range"]::-ms-fill-lower {
background-color: black; 
}
input[type="range"]::-ms-fill-upper {  
background-color: black;
}

#video-progress{
  flex:1;
}

progress {
  box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  border-radius: 10px;
  height: 5px;
}
progress::-webkit-progress-bar {
  background: darkgray;
  border-radius: 10px;
}
progress::-webkit-progress-value {
  background: white;
  box-shadow: 0px 0px 5px 1px rgb(255, 255, 255);
  border-radius: 10px;
}

#video-data-container{
  display:flex;
  padding: 5px;
  max-height:60px;
}

#video-data{
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -1px -1px #ff4848, 1px 1px 1px #8b0000;
  background-color: black;
  flex:1;
  margin-left: 10%; 
  animation: flicker 1s linear infinite;
  -webkit-animation: flicker 1s linear infinite;
  -moz-animation: flicker 1s linear infinite;
  -o-animation: flicker 1s linear infinite;
}

#video{
  box-shadow: 0 0 10px #C0C0C0;
border-radius: 4px;
transition: width 0.2s ease;
width:0;
}

#video-header, #contact-header{
  display: flex;
  justify-content: space-between;
  padding:5px;
}

#video-title{
  float: left;
  overflow: auto;
  text-wrap:nowrap;
}

i{
  height:100%;
  width:100%;
  font-size: 1.2rem !important;
}

#visualizer-container{
  width: 30%;
}

#video-controls, #pause-play-button,
#video-exit, #video-resize, #yt-button,#like-button, #comment-button{
  margin-right:5px;
}

#vol-control{
  margin-right: 20px;
}

#yt-button i{
  color: #a10202;
}

#pause-play-button, #video-exit, #video-resize, 
#yt-button, #like-button, #comment-button, #photo-exit{
  background-color: #292929;
  justify-content: center;
  align-items: center;
  display:flex;
  padding:0;
}

button {
  box-shadow: 0 0 5px #C0C0C0;
}

#video-header-controls{display:flex;}

.file-icon-container{
z-index: 1;
}

.file-icon-container:hover .file-name:not(.active){
  color: #ffca3a;
}

.image-outer-container{
  background: url("./assets/misc/bg10.gif");
  display:flex;
  padding:6px;
  border-radius: 0;
  background-size: cover;

}

.image-inner-container{
  background: url("./assets/misc/bg6.gif");
  display:flex;
  padding:5px;
  border-radius: 0;
  background-size: cover;
}

#photo-container{
  touch-action: none;
  width:fit-content;
  height:fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding:5px 20px 20px 20px;
  background-color: #292929;
  box-shadow: -1px -1px #ff4848, 1px 1px 1px #8b0000;
  overflow:auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#selected-photo{
  margin-bottom:5px;
  padding:0;
  max-height: 80vh;
  max-width: 55vw;
  box-shadow: 0 0 10px #C0C0C0;
  border-radius: 4px;
}

#photo-interact{
  margin-bottom: 5px;
  display:flex;
}

#photo-analytics{
text-align: left;
}

#photo-header{
  display: flex;
  justify-content: space-between;
  padding:5px;
}

#username{
  float:left;
}

#contact-main-container{
  display: grid;
  grid-template-columns: 20% 20% 1fr;
  grid-template-rows: 5% 20% 5% 15% 15% 1fr;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  padding: 10px;
  height: calc(100% - 20px);
  background-size: cover;
  background-repeat: no-repeat;
  animation: flicker 1s linear infinite;
  -webkit-animation: flicker 1s linear infinite;
  -moz-animation: flicker 1s linear infinite;
  -o-animation: flicker 1s linear infinite;
}

#contact-main-container>div {
  box-shadow: -1px -1px #ff4848, 1px 1px 1px #8b0000;
}

#contact-header{
  align-items: center;
  grid-row: 1;
  grid-column: 1/-1;
}

#contact-name{
  font-weight: bold;
  float: left;
}

#left-fingerprint{
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
  grid-row: 2;
  grid-column: 1;
}

#right-fingerprint{
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
  grid-row: 2;
  grid-column: 2;

}

.fingerprint-img{
  height: 65%;;
}

.fingerprint-info{
  position:absolute;
  top:0;
  left:0;
  margin:4px 0 0 4px;
}

#gender-title{
  float:left;
}
.gender{
float:right;
margin:0;
font-size: .7rem;
}

#female-gender{
  border: 1px dashed #a29696;
  color:#424242;
}

.gender, .fingerprint-info{
  width:fit-content;
  height:fit-content;
  border: 1px dashed white;
  border-radius: 0px;
  padding: 2px;
  color:rgb(204, 204, 204);
}

#face-scan{
  position: relative;
  grid-row: 2/5;
  grid-column: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

#face-scan-bar{
  width:100%;
  height:5px;
  position: absolute;
  top:0;
  left:0;
  box-shadow: 0px 0px 5px 1px rgb(47, 255, 0);
  border-radius: 10px;
  background: rgb(40, 219, 0);
  animation: oscillate 3s linear infinite;
  -webkit-animation: oscillate 3s linear infinite;
  -moz-animation: oscillate 3s linear infinite;
  -o-animation: oscillate 3s linear infinite;
}

#contact-gender{
  padding:10px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 3;
  grid-column: 1/3;
}

#contact-userid{
  position: relative;
  padding: 10px;
  grid-row: 4;
  grid-column: 1/3;
  text-align: left;
  overflow: auto;
}

#contact-id-title{
  height:20%;
  margin:0 0 10px 0;
}

#contact-id-container{
  word-break: break-all;
  display:flex;
  align-items:center;
  justify-content: center;
  text-align: center;
  height:calc(80% - 10px);
}


#contact-dna{
  position: relative;
  grid-row: 5;
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact-hr{
  overflow: hidden;
  grid-row: 5;
  grid-column: 3;
}

#contact-info{
  padding:10px;
  grid-row: 6;
  grid-column: 1/-1;
}

.contact-details{
  height:20%;
  border-radius:0px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.contact-details:nth-child(-n+4){
  border-bottom: 1px solid #C0C0C0;
}

.contact-title{
  float:left;
  font-size: .7rem;
  color:rgb(204, 204, 204);
}

.heart-rate{
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
}
.fade-in{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  right: 0;
  animation: heartRateIn 2.5s linear infinite;
  -webkit-animation: heartRateIn 2.5s linear infinite;
  -moz-animation: heartRateIn 2.5s linear infinite;
  -o-animation: heartRateIn 2.5s linear infinite;
}

.fade-out{
  position: absolute;
  width: 120%;
  height: 100%;
  background-color: #000;
  top: 0;
  right: -120%;
  animation: heartRateOut 2.5s linear infinite;
  -webkit-animation: heartRateOut 2.5s linear infinite;
  -moz-animation: heartRateOut 2.5s linear infinite;
  -o-animation: heartRateOut 2.5s linear infinite;
  background: rgba(0, 0, 0, 1);
  background: linear-gradient(to right,rgba(0,0,0,1)0%, rgba(0,0,0,1)80%,rgba(0,0,0,0)100%);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.marquee {
  display: flex ;
  overflow: hidden ;
  white-space: nowrap ;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
  background-color: black;
}
.marquee__item {
  animation-duration: 30s ;
  animation-iteration-count: infinite ;
  animation-name: marquee-content ;
  animation-timing-function: linear ;
}

.leo-name{
  color:red;
}

@keyframes marquee-content {
  from {
    transform: translateX( 0% );
  }
  to {
    transform: translateX( -100% );
  }
}

@keyframes fadeinout{
  65% { opacity: 0;}
  50% { opacity: 1; }
  40% { opacity: 0;}
}

@-o-keyframes fadeinout{
  65% { opacity: 0;}
  50% { opacity: 1; }
  40% { opacity: 0;}
}

@-moz-keyframes fadeinout{
  65% { opacity: 0;}
  50% { opacity: 1; }
  40% { opacity: 0;}
}

@-webkit-keyframes fadeinout{
  65% { opacity: 0;}
  50% { opacity: 1; }
  40% { opacity: 0;}
}

@keyframes flash{
  0% { opacity: 1; scale:0;}
  40% { opacity: 1; scale:1; }
  100% { opacity: 0; scale:0; transform: rotate(360deg);}
}

@-o-keyframes flash{
  0% { opacity: 1; scale:0;}
  40% { opacity: 1; scale:1; }
  100% { opacity: 0; scale:0; transform: rotate(360deg);}
}

@-moz-keyframes flash{
  0% { opacity: 1; scale:0;}
  40% { opacity: 1; scale:1; }
  100% { opacity: 0; scale:0; -moz-transform: rotate(360deg);}
}

@-webkit-keyframes flash{
  0% { opacity: 1; scale:0;}
  40% { opacity: 1; scale:1; }
  100% { opacity: 0; scale:0; -webkit-transform: rotate(360deg);}
}

@keyframes flicker {
  0%   { opacity:1; }
  20%  { opacity:.8; }
  50%  { opacity:1; }
  60%  { opacity:.7; }
  70%  { opacity:1; }
  90%  { opacity:.75; }
  100% { opacity:1; }
}
@-o-keyframes flicker{
  0%   { opacity:1; }
  20%  { opacity:.8; }
  50%  { opacity:1; }
  60%  { opacity:.7; }
  70%  { opacity:1; }
  90%  { opacity:.75; }
  100% { opacity:1; }
}
@-moz-keyframes flicker{
  0%   { opacity:1; }
  20%  { opacity:.8; }
  50%  { opacity:1; }
  60%  { opacity:.7; }
  70%  { opacity:1; }
  90%  { opacity:.75; }
  100% { opacity:1; }
}
@-webkit-keyframes flicker{
  0%   { opacity:1; }
  20%  { opacity:.8; }
  50%  { opacity:1; }
  60%  { opacity:.7; }
  70%  { opacity:1; }
  90%  { opacity:.75; }
  100% { opacity:1; }
}

@keyframes load {
  50%   { opacity:1; }
  0%,100% { opacity:0; }
}
@-o-keyframes load{
  50%   { opacity:1; }
  0%,100% { opacity:0; }
}
@-moz-keyframes load{
  50%   { opacity:1; }
  0%,100% { opacity:0; }
}
@-webkit-keyframes load{
  50%   { opacity:1; }
  0%,100% { opacity:0; }
}

@keyframes oscillate {
  50%   { top:100%; }
  0%,100% { top:0%; }
}
@-o-keyframes oscillate{
  50%   { top:100%; }
  0%,100% { top:0%; }
}
@-moz-keyframes oscillate{
  50%   { top:100%; }
  0%,100% { top:0%; }
}
@-webkit-keyframes oscillate{
  50%   { top:100%; }
  0%,100% { top:0%; }
}

@keyframes heartRateIn{
	0%{
		width: 100%;
	}
	50%{
		width: 0;
	}
	100%{
		width: 0;
	}
}
@-o-keyframes heartRateIn{
	0%{
		width: 100%;
	}
	50%{
		width: 0;
	}
	100%{
		width: 0;
	}
}
@-moz-keyframes heartRateIn{
	0%{
		width: 100%;
	}
	50%{
		width: 0;
	}
	100%{
		width: 0;
	}
}
@-webkit-keyframes heartRateIn{
	0%{
		width: 100%;
	}
	50%{
		width: 0;
	}
	100%{
		width: 0;
	}
}
@keyframes heartRateOut{
	0%{
		left: -120%;
	}
	30%{
		left: -120%;
	}
	100%{
		left: 0;
	}

}
@-o-keyframes heartRateOut{
	0%{
		left: -120%;
	}
	30%{
		left: -120%;
	}
	100%{
		left: 0;
	}

}
@-moz-keyframes heartRateOut{
	0%{
		left: -120%;
	}
	30%{
		left: -120%;
	}
	100%{
		left: 0;
	}

}
@-webkit-keyframes heartRateOut{
	0%{
		left: -120%;
	}
	30%{
		left: -120%;
	}
	100%{
		left: 0;
	}

}

@media(max-width:1024px){
  #vol-control{
    display:none;
  }
}

@media (max-width:650px){
  
  #tab-container {
    grid-column: 3;
    grid-row: 3;
  }

  #main-section {
    grid-column: 1/3;
    grid-row: 3;
    margin-left:0px;
    height: calc(100vh - 220px);
  }

  #left-section {
    grid-column: 2/4;
    grid-row: 2;
    margin-left: 10px;
    min-height: 100px;
  }

  #icon-section {
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
    resize: none;
  }

  #main-container {
    grid-template-columns: auto 1fr 15vw;
    grid-template-rows: auto 0fr 1fr;
  }

  #left-body {
    height: 50%;
    overflow-y: auto;
    padding: 5px 5px;
    text-align: center;
    margin: 10px 10px;
  }

  #header-section {
    height: 40px;
  }

  .video-logo, .video-logo-flash{
    width:36px;
    height:36px;
  }

  #video-icon-holder{
    width:36px;
    height:36px;
  }
  .photo-logo{
    max-width:60px;
    max-height:60px;
  }

  .marquee{
    font-size: 25px;
  }

  *{
    font-size: .8rem;
  }
}